import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import Wrapper from "../components/styles/Wrapper";

const HomeContentStyle = styled.section`
  padding: 20px;
  text-align: center;

  h1 {
    font-weight: 700;
    margin-bottom: 0;
    color: var(--pink);
  }

  h2 {
    font-size: 1.2em;
    text-align: center;
    padding: 0;
    color: var(--light);
    text-transform: uppercase;
  }
`;

const CardHolder = styled.div`
  margin: 0 64px;
  @media (max-width: 800px) {
    margin: 0;
  }
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

const Card = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  width: 270px;
  box-sizing: border-box;
  /* border: 1px solid rgba(172, 172, 172, 0.1); */
  border-radius: 4px;
  box-shadow: 0 0 30px rgb(0 0 0 / 20%);
  /* box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
   */
  background-color: white;

  .one {
    background-color: var(--pink);
    border-radius: 4px 4px 0 0;
  }

  .two {
    background-color: var(--yellow);
    border-radius: 4px 4px 0 0;
  }

  .three {
    background-color: var(--green);
    border-radius: 4px 4px 0 0;
  }

  .cardText {
    margin: 0 15px 10px 15px;

    p {
      font-size: 1rem;
      text-align: left;
      line-height: 162.7%;
      color: var(--light-gray);
    }
  }
`;

const HomeServices = () => {
  return (
    <HomeContentStyle>
      <Wrapper>
        <h1>Our Services</h1>
        <CardHolder>
          <Card>
            <div className="one">
              <h2>Soft Play</h2>
            </div>
            <StaticImage src="../assets/images/softplay-fence-blue.jpg" />
            <div className="cardText">
              <p>
                We create a playground area that provides a fun and safe
                environment for your little ones to play and explore.
              </p>
              <p>Ideal for crawlers and toddlers' parties.</p>
            </div>
          </Card>
          <Card>
            <div className="two">
              <h2>Ball Pits</h2>
            </div>
            <StaticImage src="../assets/images/premium-ballpit-pink-square.jpg" />
            <div className="cardText">
              <p>
                Our Luxury Ball Pits are suited to entertain ALL-AGES! They are
                the perfect addition to any celebration.
              </p>
              <p>
                Customize your ball pit by choosing from a wide variety of ball
                colors and vinyl decals.
              </p>
            </div>
          </Card>
          <Card>
            <div className="three">
              <h2>White Castles</h2>
            </div>
            <StaticImage src="../assets/images/white-inflatable-castle.jpg" />
            <div className="cardText">
              <p>
                Modern white bouncers are the perfect canvas to create a unique
                design for your celebration.
              </p>
              <p>
                Perfect for weddings, birthday parties, or any other special
                event that needs a unique twist.
              </p>
            </div>
          </Card>
        </CardHolder>
      </Wrapper>
    </HomeContentStyle>
  );
};

export default HomeServices;
