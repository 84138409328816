import * as React from "react";
import Layout from "../components/Layout";
import Hero from "../components/Hero";
import HomeServices from "../components/HomeServices";
import Seo from "../components/Seo";

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <Hero />
      <HomeServices />
    </Layout>
  );
};

export default IndexPage;
