import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import Wrapper from "../components/styles/Wrapper";
import Button from "../components/styles/Button";

const HeroContent = styled.section`
  margin: 0 64px;
  @media (max-width: 800px) {
    margin: 5px;
  }
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  justify-content: space-between;
`;

const HeroText = styled.div`
  flex-basis: 40%;

  h1 {
    font-size: 2.2rem;
    color: var(--dark-gray);
    font-weight: 900;
    letter-spacing: -0.03em;
    line-height: 92.68%;
    padding: 0 30px 0 0;
    text-align: left;
  }

  p {
    /* background-color: red; */
    font-size: 1rem;
    font-weight: 400;
    text-align: left;
    line-height: 162.7%;
    color: var(--light-gray);
    text-align: left;
  }

  .darker {
    font-weight: 700;
    padding-bottom: 5px;
  }

  @media (max-width: 768px) {
    flex-basis: 100%;
    margin-bottom: 30px;
  }
`;

const TextContainer = styled.div`
  width: 80%;
  /* height: 100%; */

  @media (max-width: 1024px) {
    width: 90%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ImageContainer = styled.div`
  width: 60%;
  height: 100%;
  align-self: center;

  border: 10px solid #ffffff;
  box-sizing: border-box;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Hero = () => {
  return (
    <Wrapper>
      <HeroContent>
        <HeroText>
          <TextContainer>
            <h1>OKC SOFT PLAY & BALL PIT RENTAL</h1>
            <p>
              We provide modern and engaging soft play areas designed for babies
              and toddlers. We also offer white bounce houses and ball pits
              suited for all ages.
            </p>
            <p className="darker">Serving the OKC Metro area and beyond.</p>
            <div>
              <Button type="normal" to="/packages" activeClassName="active">
                View Packages
              </Button>
            </div>
          </TextContainer>
        </HeroText>
        <ImageContainer>
          <StaticImage
            src="../assets/images/royal-ballpit-hero.jpg"
            alt="white castle and ball pit"
            layout="fullWidth"
          />
        </ImageContainer>
      </HeroContent>
    </Wrapper>
  );
};

export default Hero;
